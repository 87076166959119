import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  pageType: 'core',
  pageName: 'home',
  parentPageInfos: null,
  title: "Back Market: Refurbished tech that's better for the planet",
  badge: '',
  titleSeo: "Refurbished tech that's better for the planet",
  titleBreadcrumb: 'Home',
  banner: {},
  meta: {
    title: "Back Market: Refurbished tech that's better for the planet",
    description:
      'Find the best deals on refurbished phones, laptops, and tech at Back Market. Up to 70% off when compared to new products. Free delivery, 1-year warranty, 30-day money back guarantee.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/906aecd28464a3996c514d1b02a00983/SEO-Website-Thumbnail.png',
      srcMobile: '',
      alt: '',
      width: 516,
      height: 258,
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-fr',
      },
      {
        country: 'BE',
        locale: 'fr-be',
      },
      {
        country: 'ES',
        locale: 'es-es',
      },
      {
        country: 'DE',
        locale: 'de-de',
      },
      {
        country: 'IT',
        locale: 'it-it',
      },
      {
        country: 'GB',
        locale: 'en-gb',
      },
      {
        country: 'AT',
        locale: 'de-at',
      },
      {
        country: 'NL',
        locale: 'nl-nl',
      },
      {
        country: 'US',
        locale: 'en-us',
      },
      {
        country: 'FI',
        locale: 'fi-fi',
      },
      {
        country: 'PT',
        locale: 'pt-pt',
      },
      {
        country: 'IE',
        locale: 'en-ie',
      },
      {
        country: 'GR',
        locale: 'el-gr',
      },
      {
        country: 'SK',
        locale: 'sk-sk',
      },
      {
        country: 'SE',
        locale: 'sv-se',
      },
      {
        country: 'JP',
        locale: 'ja-jp',
      },
      {
        country: 'AU',
        locale: 'en-au',
      },
    ],
  },
  blocks: [
    {
      id: '4b9xd77IDspi96ckYZt2Lq',
      type: 'Carousel',
      hiddenDevices: [],
      props: {
        slides: [
          {
            id: 'hdAFLysIt0rnKFMkTLLBb',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6ftzgVlwDWYTxzFy3eY5Cw/f9253bace06cbba3ecfb98e8daca92a3/DN_HP-Banners_2_desktop_US.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3uuW2HCNT83oEKKdSHnWeE/15b2ec7b529abf86947e855df4a78748/DN_HP-Banners_2_mobile_US.jpg',
              alt: '[US] [HP] iPhones PLP x Downgrade Now',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.com/en-us/l/iphone/e8724fea-197e-4815-85ce-21b8068020cc',
            },
          },
          {
            id: '1mAue3FdCB3TxfyFu5lgpw',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3MsolfbYgGOEYFpCDYzAK9/2b1c9781d80e10cd0237f4c332b4096f/Visible_CommCampaign_HP-Banner_A_Desktop_Oct21.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3fhr5BFbcpjqbxhulAMHwg/bebf2c62aa0ea013a442910003391326/Visible_CommCampaign_HP-Banner_A_Mobile_Oct21.jpg',
              alt: 'US - Visible',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.com/en-us/service/visible-by-verizon',
            },
          },
          {
            id: '4N8uKCQdVyjmucY06tnQPH',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/t8JhndTyTa1sDJIhaH37I/f70c90624156c0562ad41e083da5e9e5/GENERIC_HPBANNERS_MacBookAirM1_Desktop_US.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3OzwP7KaRBF9yWmU0bg9ol/3bb3ddddef0592f4a92ca8bedeaadd0c/GENERIC_HPBANNERS_MacBookAirM1_Mobile_US.jpg',
              alt: 'US- Macbook Generic',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.com/en-us/l/apple-macbook/a059fa0c-b88d-4095-b6a2-dcbeb9dd5b33',
            },
          },
          {
            id: '5DUsMnBLlh24JHr9dVz8E',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3Xpl0rtrXbBRIZDONnbeNm/da1ec02caca4b8dcba2f8c5b31c68d6b/GENERIC_HPBANNERS_iPad2021_Desktop_US.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4dTq6pg9DEX9PgVUx3a5Si/263229d63956654e47b0ec1d6db912b1/GENERIC_HPBANNERS_iPad2021_Mobile_US.jpg',
              alt: 'US - iPad x Generic',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: null,
          },
          {
            id: '3fNNLDcqB9yJ0LJZj3sIot',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1D2wIcVKyw9g4OiKkqwqft/479e35d9922f4aa5cd4b1fa7db2b48e0/HP_Desktop__1_.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/fdNGgHRs5tB0sS0rYrzKO/0e21038b5044619c32382f71f476150d/HP_Mobile__1_.jpg',
              alt: 'US - international repair image',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.com/en-us/c/tech-education/what-is-right-to-repair',
            },
          },
          {
            id: '0FrPtusdKvJLJ5cOkSwzb',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3f7QI4mpQCI50kDO9fp1ZU/d789e1409280581f8070eb219fd244d9/DN_HP-Banners_1_desktop-US-v3.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4j7gwRW5YcMhqNepSXptFQ/97fe9e0c9d932da80b9c6b00199f11db/DN_HP-Banners_1_mobile-US-v4.jpg',
              alt: 'US - Downgrade Now',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.com/en-us/e/refurbished-event',
            },
          },
          {
            id: '1ot4PyxjVn09HvgZQJnaFg',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2aL2c9hKAhSlHiZCfOk16e/4f247ae3c71f011a940ccb7b3b79cf5a/TRADEIN_HPBanner_Desktop_US.jpeg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2wtqSKOu13birK5LRe5UMs/88fa9a2179da72552c92b5ed0a601c65/TRADEIN_HPBanner_Mobile_US.jpeg',
              alt: 'US - Trade-in x Keynote 2024',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://www.backmarket.com/en-us/buyback/home',
            },
          },
          {
            id: '6pPTYp3V0QUFUQIv8pURPA',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3c6DVuZZ92nnOpVacHr8u6/f94d7949e679d137482ccaa9e4c464fb/TRADEIN_HP_GENERICBANNERS_DESKTOP_1_US.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5E429jwhLbo4xp0DRYb0RL/de733978c9b41f66dab41162cbfe75e9/TRADEIN_HP_GENERICBANNERS_MOBILE_1_US.jpg',
              alt: 'US - Trade-In x evergreen',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: {
              href: 'https://preprod.backmarket.com/en-us/buyback/home',
            },
          },
          {
            id: '4bWz1YvnE6gaFzyHIKVu1O',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6B8nypobeX6vexMehAJIgQ/e78261a0b1dfcab6a8379da4deddee14/GENERIC_HPBANNERS_GalaxyS21_Desktop_US.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5LQAwtm8bWlezRPl6MfS6T/fcce946e3a2313ffebfb184e8c56124a/GENERIC_HPBANNERS_GalaxyS21_Mobile_US.jpg',
              alt: 'US - Galaxy x Generic Banner',
              width: 2880,
              height: 686,
              widthMobile: 750,
              heightMobile: 750,
            },
            link: null,
          },
        ],
      },
    },
    {
      id: 'VyWxLwfjIiJdI3CSeY1fZ',
      type: 'ModularCardsCarousel',
      hiddenDevices: [],
      props: {
        title: 'How does the latest iPhone compare ?',
        subtitle: 'Here are the facts ',
        size: 'medium',
        cardsHavePadding: true,
        layout: 'carousel',
        mobileScreenColumns: 1,
        cards: [
          {
            id: '55sgaRtJQvgJVyZZ1DK4M8',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '1W1JnaHUiSSSFedcYb0QxW',
                    type: 'Media',
                    hiddenDevices: [],
                    props: {
                      title: 'iPhone 16',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/477nQMIRqiarsrEUKfhkbe/327e3b6c7e8a15531a0b178761e36250/iphone_16__2_.png',
                        srcMobile: '',
                        alt: 'iPhone 16 - Blue - Modular Card',
                        width: 2240,
                        height: 2240,
                      },
                    },
                  },
                ],
              },
              {
                alignment: 'left',
                slots: [
                  {
                    id: 'hRpyE7g6XuPLYGwWdOOy4',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: '6.1" OLED display',
                      icon: {
                        icon: 'IconCheckInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '1U4hRAUTJMiqKrWmd8ISVq',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Screen Refresh Rate: 60Hz',
                      icon: {
                        icon: 'IconCheckInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '15xwFT0mioYjOPYzQ1uGnW',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Dynamic Island',
                      icon: {
                        icon: 'IconCheckInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '1XSdI9UsGyCkxsMNJckqfr',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Camera: 48MP',
                      icon: {
                        icon: 'IconCheckInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '54SVJFHtiuezgNp78EjsHK',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Zoom x2',
                      icon: {
                        icon: 'IconCheckInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '5O5j14mxFWgZD2EnR6SFTX',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'USB-C',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '3mUA5CCxfthvjoGUHMRAid',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Apple Intelligence',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '7esQwg0NlVvENNVUImsYLf',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Action Button',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '4QapOAd8YsC2fwUN7KkuuO',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Camera Control',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          {
            id: 'vorvJUHHlFJO6H86d6Per',
            sections: [
              {
                alignment: 'center',
                slots: [
                  {
                    id: '7tXNjcW1jiSVNTdVxDykgB',
                    type: 'Media',
                    hiddenDevices: [],
                    props: {
                      title: 'iPhone 15 Pro',
                      image: {
                        src: 'https://images.ctfassets.net/mmeshd7gafk1/3Kfamhci4URaVqfBYaD7fJ/ac0ddf911e531d39dd73d8bea37613ba/iPhone_15_Pro.png',
                        srcMobile: '',
                        alt: 'iPhone 15 Pro - Black - Modular Card',
                        width: 2240,
                        height: 2240,
                      },
                    },
                  },
                ],
              },
              {
                alignment: 'left',
                slots: [
                  {
                    id: 'hRpyE7g6XuPLYGwWdOOy4',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: '6.1" OLED display',
                      icon: {
                        icon: 'IconCheckInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: 'znjgTS5atlCaTbi7jUxtB',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Screen Refresh Rate: 120Hz',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '15xwFT0mioYjOPYzQ1uGnW',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Dynamic Island',
                      icon: {
                        icon: 'IconCheckInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '1XSdI9UsGyCkxsMNJckqfr',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Camera: 48MP',
                      icon: {
                        icon: 'IconCheckInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '71jXheHfS550z0BEYMb4G4',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Zoom x3',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '5O5j14mxFWgZD2EnR6SFTX',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'USB-C',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '3mUA5CCxfthvjoGUHMRAid',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Apple Intelligence',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '7esQwg0NlVvENNVUImsYLf',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Action Button',
                      icon: {
                        icon: 'IconStarFilled',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                  {
                    id: '6ZV6K6jjGPQeIBuES3i0by',
                    type: 'ListItem',
                    hiddenDevices: [],
                    props: {
                      text: 'Camera Control',
                      icon: {
                        icon: 'IconCrossInCircle',
                        color: 'primary',
                        size: 'medium',
                      },
                    },
                  },
                ],
              },
              {
                alignment: 'center',
                slots: [
                  {
                    id: '6U43NkhUpVx2QzA1tk6Hsi',
                    type: 'Button',
                    hiddenDevices: [],
                    props: {
                      variant: 'primary',
                      size: 'normal',
                      ctaLink: {
                        label: 'Buy now',
                        link: {
                          href: 'https://www.backmarket.com/en-us/l/iphone-15-pro/de96c5f1-0344-4a5e-98ec-498d23e40824',
                        },
                      },
                    },
                  },
                ],
              },
            ],
            background: {
              theme: 'white',
            },
          },
          // {
          //   id: '5RRCGs1TF6QrJMszFUz6Wt',
          //   sections: [
          //     {
          //       alignment: 'center',
          //       slots: [
          //         {
          //           id: '2PRZQNdarXcQaFhTMSaAp1',
          //           type: 'Media',
          //           hiddenDevices: [],
          //           props: {
          //             title: 'iPhone 14 Pro',
          //             image: {
          //               src: 'https://images.ctfassets.net/mmeshd7gafk1/2wgg8LwpMTrwQLvvKiUIV6/ffcbed55243dc06fad877d3ab068fb9e/iPhone_14_Pro.png',
          //               srcMobile: '',
          //               alt: 'iPhone 14 Pro - Yellow - Modular Card',
          //               width: 2240,
          //               height: 2240,
          //             },
          //           },
          //         },
          //       ],
          //     },
          //     {
          //       alignment: 'left',
          //       slots: [
          //         {
          //           id: 'hRpyE7g6XuPLYGwWdOOy4',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: '6.1" OLED display',
          //             icon: {
          //               icon: 'IconCheckInCircle',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //         {
          //           id: 'znjgTS5atlCaTbi7jUxtB',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: 'Screen Refresh Rate: 120Hz',
          //             icon: {
          //               icon: 'IconStarFilled',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //         {
          //           id: '15xwFT0mioYjOPYzQ1uGnW',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: 'Dynamic Island',
          //             icon: {
          //               icon: 'IconCheckInCircle',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //         {
          //           id: '1XSdI9UsGyCkxsMNJckqfr',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: 'Camera: 48MP',
          //             icon: {
          //               icon: 'IconCheckInCircle',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //         {
          //           id: '71jXheHfS550z0BEYMb4G4',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: 'Zoom x3',
          //             icon: {
          //               icon: 'IconStarFilled',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //         {
          //           id: '1wvIXElNyOshhdDwYwZQYh',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: 'USB-C',
          //             icon: {
          //               icon: 'IconCrossInCircle',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //         {
          //           id: '4n1Q9xKKS22F7fzUv3mq4z',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: 'Apple Intelligence',
          //             icon: {
          //               icon: 'IconCrossInCircle',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //         {
          //           id: '3jaowi6mB2CMBMjTphtAEz',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: 'Action Button',
          //             icon: {
          //               icon: 'IconCrossInCircle',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //         {
          //           id: '6ZV6K6jjGPQeIBuES3i0by',
          //           type: 'ListItem',
          //           hiddenDevices: [],
          //           props: {
          //             text: 'Camera Control',
          //             icon: {
          //               icon: 'IconCrossInCircle',
          //               color: 'primary',
          //               size: 'medium',
          //             },
          //           },
          //         },
          //       ],
          //     },
          //     {
          //       alignment: 'center',
          //       slots: [
          //         {
          //           id: '7cW87y4qYymT2ct9worqVG',
          //           type: 'Button',
          //           hiddenDevices: [],
          //           props: {
          //             variant: 'primary',
          //             size: 'normal',
          //             ctaLink: {
          //               label: 'Buy Now',
          //               link: {
          //                 href: 'https://www.backmarket.com/en-us/l/iphone-14-pro/9c661711-8fbe-4b55-b8aa-a0bb42ccfeed',
          //               },
          //             },
          //           },
          //         },
          //       ],
          //     },
          //   ],
          //   background: {
          //     theme: 'white',
          //   },
          // },
        ],
      },
    },
    {
      id: '4o9KAO3AJAIobAd6Y46UlQ',
      type: 'Categories',
      hiddenDevices: [],
      props: {
        title: 'Popular devices',
        subtitle: 'You can totally sit with them',
        categories: [
          {
            id: '6VbSdsrrdLfZEvJCGlfh9i',
            title: 'iPad',
            tagline: 'Pad your pockets (or anything else)',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4mw1VnREHuLh2fhAkwD1z4/640df998b79c5ad6b0ef062b2485c6a2/None_dae26af1-c478-409d-ae33-adc.png',
              srcMobile: '',
              alt: 'iPad Pro 2022',
              width: 400,
              height: 400,
            },
            link: null,
          },
          {
            id: '6IqBa2a5RYrJmQSnsIqQ1W',
            title: 'iPhone',
            tagline: 'Bring home a smarter phone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6qnP8LnQK1eaaXF3gD470P/8cd499f149f310b394622321231152be/iPhone_13.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6OG8m4u6D6Tf2uj2CEDf4l/d86903a409dbc42e36d3c10753520d6a/iPhone_13.jpg',
              alt: 'iPhone 13',
              width: 400,
              height: 400,
              widthMobile: 200,
              heightMobile: 200,
            },
            link: null,
          },
          {
            id: '2oy8oE7yLNJxmQCoiUUgX2',
            title: 'MacBook',
            tagline: 'All of the buttons, all of the savings',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/9MX5iyCngiIXMgzDS29OV/ad19cf5f8e0132538a887484222e7f77/MacBook_Air__2022__M2.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/HGe7hQv5gWmrrG5kj0o5s/89db3e4a822bd455c9d60b45fe4a1c3e/MacBook_Air__2022__M2.png',
              alt: 'MacBook Air M2',
              width: 400,
              height: 400,
              widthMobile: 200,
              heightMobile: 200,
            },
            link: null,
          },
          {
            id: '434irJ34AkNGTsQnhBQodY',
            title: 'Gaming consoles',
            tagline: 'From A to X',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2AL0fYrAyeOLASNKARww46/50f8ac7ec9b54791d9638e260339de6b/ps5-2.jpg',
              srcMobile: '',
              alt: 'ps5',
              width: 400,
              height: 400,
            },
            link: null,
          },
          {
            id: '5UBsVSVg5xU2ZNpdITuZVC',
            title: 'Chromebooks & Laptops',
            tagline: 'Premium laptops without the premium prices',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5wRFyaf2nEBVlUHR5SZuq2/8af030b887c31b77a5e7c25cf7cdd4b8/Chromebooks___Laptops.jpg',
              srcMobile: '',
              alt: 'Laptops',
              width: 400,
              height: 400,
            },
            link: null,
          },
        ],
      },
    },
    {
      id: '29VqAbWDVl0muAfWyXn2sh',
      type: 'VideosBlock',
      hiddenDevices: [],
      props: {
        title: 'Behind the Tech',
        videos: [
          {
            id: '6IAfXNW8NojWmsfvSUVXde',
            video: {
              title: 'How to make your phone battery last longer',
              description: '',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/4bwx41LMSQ0o6mBF6iQREV/f9c33a06c5ea0319226798502795c3af/BATTERY-EN-ss.mp4',
                details: {
                  size: 332168776,
                },
                fileName: 'BATTERY-EN-ss.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1eSFzHvw5tbMCX59ndFHCr/64ca13d7a63e54e7c1a36bd7ee84c5a9/Thumbnail-9_16-EN.jpg',
              srcMobile: '',
              alt: 'US - Tech tips #1 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '7gI5DyoYVzAop49ar0T4JW',
            video: {
              title: 'Mystery Order',
              description: '',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/5wSuNysuhvkYxcsgQYSaqs/06c87c36307f74e72dda45c7cc642936/EN_Mystery_Order_vertical.mp4',
                details: {
                  size: 346645264,
                },
                fileName: 'EN_Mystery Order_vertical.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/Y5r9LOiLBqSVj73TwFHT6/c26c4e550bc5dfaa13b84b41c794bb67/Mystery_order_-_Thumbnail_-_US.jpg',
              srcMobile: '',
              alt: 'US - Tech tips #5 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '3LQlKBPQERDI3juFGydCmX',
            video: {
              title: 'What is the best iPad to buy?',
              description: '',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/1tKBCXe989lwAbp5LodXgD/99097bb13f54b04160cd44c8c31ffb65/IPAD-EN.mp4',
                details: {
                  size: 366546057,
                },
                fileName: 'IPAD-EN.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6dKgvdw1apQq9bO99r2ig2/414d4c20ad96de98ad9284b4c94722d8/V2-Thumbnail-vertical-EN.jpg',
              srcMobile: '',
              alt: 'US - Tech tips #2 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '5jOZJaRE28jtbXje73Reyx',
            video: {
              title: '3 tips to look after your laptop',
              description: '',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/64ftsRVzyvCZN4NcuLBJia/02ca107b61138122f97ec2ef5a5cbc4c/VDEF-veritcal-EN-3tips-SS.mp4',
                details: {
                  size: 345353480,
                },
                fileName: 'VDEF-veritcal-EN-3tips-SS.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2TtGmjeFTHHMjEEOJhVPTD/f95955cefc7131ea3b96ddeb21618102/EN-3_tips.png',
              srcMobile: '',
              alt: 'US - Tech tips #6 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '3HJmngcPYkUbkL3RMvzmcK',
            video: {
              title: 'Do you need a new battery?',
              description: '',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/21kshcQaaNz8bCm9ysrK67/5c7e47edd2fac8c932cfd76691d8d7df/NEW-BATTERY-EN-verticale.mp4',
                details: {
                  size: 325610612,
                },
                fileName: 'NEW-BATTERY-EN-verticale.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/ClVTKvH54ELRJ1FRTqQpX/f6099295c43c7b5cafd1c4f4f14f167c/NEW-BATTERY-EN-verticale-thumbnail.jpg',
              srcMobile: '',
              alt: 'US - Tech tips #3 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: 'lH875wuVuKmYQ9VUH3vjP',
            video: {
              title: 'Myths about your tech',
              description: '',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/61VA6aHtact4M9mSkmsw1p/ba19c8111d1e4a0faa9e1ad0a2322e11/Vertical_EN_Myths.mp4',
                details: {
                  size: 171229788,
                },
                fileName: 'Vertical EN Myths.mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/783hnPO9FZhgsvY1lSzuNO/95ecb0f84ef800437e7bd05ff339c399/Myths_about_your_tech_-_Thumbnail_-_US.jpg',
              srcMobile: '',
              alt: 'US - Tech tips #7 thumbnail',
              width: 256,
              height: 455,
            },
          },
          {
            id: '5J00z4F9euKRyl7BvDs3lS',
            video: {
              title: 'Which MacBook processor should I choose?',
              description: '',
              file: {
                url: '//videos.ctfassets.net/mmeshd7gafk1/1Cnlvrju7Akgw0EtztkWvm/c9617af6ac869ebde62428d55c794b2f/V2-PROCESSEUR-EN-VERTICAL__1_.mp4',
                details: {
                  size: 279715863,
                },
                fileName: 'V2-PROCESSEUR-EN-VERTICAL (1).mp4',
                contentType: 'video/mp4',
              },
            },
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1Mfr0p5uIz64nuvRlzalDq/dca72b7b18cc290c0defbc6e18fb1552/PROCESSOR-EN-thumbnail-9_16__2_.jpg',
              srcMobile: '',
              alt: 'US - Tech tips #4 thumbnail',
              width: 256,
              height: 455,
            },
          },
        ],
      },
    },
    // {
    //   id: '2Jvl9pCerGhP3WqLojAauP',
    //   type: 'VideosBlock',
    //   hiddenDevices: [],
    //   props: {
    //     title: 'Community stories',
    //     videos: [
    //       {
    //         id: '6lczMCyUvwCiZ5S56tjd4a',
    //         video: {
    //           title: 'Unboxing your iPhone',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/1P3fXySQ24y2DDlPUiOKpi/da32e00790b4748963c3cc57195fa6e5/Back_Market_Unboxing_iPhone_v3c_916_master_122723.mp4',
    //             details: {
    //               size: 16210709,
    //             },
    //             fileName:
    //               'Back Market Unboxing_iPhone v3c 916 master 122723.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/6F90fjdcOYLFDPIPlax72R/b3b15b546c702efdd8810683ef6a7ff5/video-capture-5212.png',
    //           srcMobile: '',
    //           alt: '',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: 'pS8w3iHg6f4ILrx5Nq1SJ',
    //         video: {
    //           title: 'Buying refurbished 101',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/4xlTW1KErztFh9CgbsTWBF/1c084d8f1be11b212b83400828b31f49/Refurb_101_ENG.mp4',
    //             details: {
    //               size: 12042325,
    //             },
    //             fileName: 'Refurb 101 ENG.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/2ehbaZDbuBBaXPwQvHYOEn/8a0e7150470ca09f78178bb44f072eb6/video-capture-813.png',
    //           srcMobile: '',
    //           alt: '',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '6TYLf18W4ZD6s77niIIvYK',
    //         video: {
    //           title: 'Unboxing your MacBook',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/56jz748j9dZ9d11klMm0cq/2007977b4d5640dd0947432f24dc899b/Back_Market_Unboxing_Macbook_v2d_916_master_122823.mp4',
    //             details: {
    //               size: 26509533,
    //             },
    //             fileName:
    //               'Back Market Unboxing_Macbook v2d 916 master 122823.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/3EqvUlrZjVXwXmWiEhuEdA/1fb4baecb0e7f17800b50f345658e55d/video-capture-397.png',
    //           srcMobile: '',
    //           alt: '',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '6kdoPfOxzRIknVNRrD3IUO',
    //         video: {
    //           title: 'MacBook Pro 13 savings!',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/eOwOHZkeGCaPjRffLCqwL/5071df635ebd17e1a40978b4a2db8db5/Download.mp4',
    //             details: {
    //               size: 1816103,
    //             },
    //             fileName: 'Download.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/6bMC6WVmTyJ03aUsns1EIs/e4795eae3033c9eceebbeb6136c5b617/Capture_d_e_cran_2023-10-06_a__16.54.36.png',
    //           srcMobile: '',
    //           alt: 'MacBook Pro 13',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '2NuGjMRoiJmGzkUkH2U310',
    //         video: {
    //           title: 'Lucy with sign',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/7lsLFGMJ7wmE8d9DnQn7iQ/2d9c78ccb0bc2567958bdcbfd6d6a39d/LucyWithASign_Music_V2.mp4',
    //             details: {
    //               size: 26587159,
    //             },
    //             fileName: 'LucyWithASign_Music_V2.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/2Lx3UBQ8tOPPKho72bhpSO/64db4ebd3f0ad1c478f6056f29a6f124/video-capture-8933.png',
    //           srcMobile: '',
    //           alt: '',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '7gpSvkXcOtH5bZA6b4TByF',
    //         video: {
    //           title: 'FAQs on Mystery Orders',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/4UnvhqX3J4AoyY7rjr1PBR/5876ad0c1b528378a3a42f932eb30434/FAQs_Mystery_Order_Copy_Music.mp4',
    //             details: {
    //               size: 28219782,
    //             },
    //             fileName: 'FAQs_Mystery Order_Copy+Music.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/8BTdwopD6aU2Al81tT6CL/36f101f8fea5a327e1a2013b0c05cf40/Capture_d_e_cran_2023-10-06_a__16.54.59.png',
    //           srcMobile: '',
    //           alt: 'Mystery orders',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '2crT0tuL4XiJPiwXK3AT8a',
    //         video: {
    //           title: 'US - FAQs on How BM works',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/3c7pLif53tM08xYpppVDJn/8e8c5c426162862455c00edcc20964e8/FAQs_How_BM_Works.mp4',
    //             details: {
    //               size: 92004181,
    //             },
    //             fileName: 'FAQs_How BM Works.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/MGov6J53iF3tV50x92fxE/a37024bd3d03cfb7e7cc6a9ecd5908c4/Capture_d_e_cran_2023-10-06_a__16.23.30.png',
    //           srcMobile: '',
    //           alt: 'How Back Market works',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '6WPjr6LTixoBMdsiwuh6YR',
    //         video: {
    //           title: 'How to trade-in?',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/1301qMwsxxGWtclGyUBXTW/c46f0b3b90ea34e4b8064e6cf5c9c848/How_to_Trade-in_Delivery.mp4',
    //             details: {
    //               size: 29681396,
    //             },
    //             fileName: 'How to Trade-in Delivery.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/7wIITJXMdFD3ZhjYYPG3Li/86342261034b1d6f4ebc6fce352b7b22/video-capture-6387.png',
    //           srcMobile: '',
    //           alt: '',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '5kEizMPZHrPUlkVgol9LjR',
    //         video: {
    //           title: 'We think therefore we R',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/w031nl6q5FccxLXsbNIr6/c5cfcb30973e458a5d018b69072c6fc6/PROJRREVEAL.mp4',
    //             details: {
    //               size: 37147277,
    //             },
    //             fileName: 'PROJRREVEAL.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/5gmmkoBxaI4fhniUmLZw1J/a99baf115368c0e1bab55ad43ba5d37d/Capture_d_e_cran_2023-10-06_a__16.55.25.png',
    //           srcMobile: '',
    //           alt: 'Project R reveal',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '1WEhSUS0ErnnnbdcuC9Cd4',
    //         video: {
    //           title: 'Unboxing',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/2j4wiCUqNaujB5ougxiSVd/783ce09bb94859448ad4ffd740643324/August_UR_CJ.mp4',
    //             details: {
    //               size: 27998338,
    //             },
    //             fileName: 'August_UR_CJ.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/3KsbzADJuzSjexquDTwbGK/6a00b4b5501282c5cc6779740be3b11c/Capture_d_e_cran_2023-10-06_a__16.55.40.png',
    //           srcMobile: '',
    //           alt: 'Unboxing iphone',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '7cTOGoMqVqGt8f7cpdYaAS',
    //         video: {
    //           title: 'How to clean your AirPods?',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/20bhgq1uUX7bjI0SddzqdL/a068b61bb3959b2eea79bf7852465fb7/Download_1_.mp4',
    //             details: {
    //               size: 4584188,
    //             },
    //             fileName: 'Download(1).mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/AHLCY5rspOxMxQPfyB1mW/0450744aa60cc1e33b9ff549cceb763d/Capture_d_e_cran_2023-10-06_a__16.56.24.png',
    //           srcMobile: '',
    //           alt: 'How to clean your AirPods',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '5AgXGm5HewRdfy0kCIh44Z',
    //         video: {
    //           title: 'isBmLegit - Video title',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/42tEK7GxIlLAAOsolIUkiN/0456aa01a9095801536f89678494b042/isBmLegit.mp4',
    //             details: {
    //               size: 2600200,
    //             },
    //             fileName: 'isBmLegit.mp4',
    //             contentType: 'video/mp4',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/76dxSaagF4iuuHVPzjYyEz/2a36063f29713242ba43d3331e20d445/Capture_d_e_cran_2023-11-08_a__1__1_.png',
    //           srcMobile: '',
    //           alt: 'iPhone 15 - SavageReview',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //       {
    //         id: '1HXnn6S6cQ3tV96yVfoFuz',
    //         video: {
    //           title: 'V1 vertical Mystery Order FR (1)',
    //           description: '',
    //           file: {
    //             url: '//videos.ctfassets.net/mmeshd7gafk1/6KSnPx6ZZukBfDZwZAIzUr/588e7a6c8dda147c1a66914897e0e509/V1_vertical_Mystery_Order_FR__1_.mov',
    //             details: {
    //               size: 389780816,
    //             },
    //             fileName: 'V1_vertical_Mystery_Order_FR (1).mov',
    //             contentType: 'video/quicktime',
    //           },
    //         },
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/4lzJCErnDvg1bKQK80yM7N/5e9a211b566513e72df042d34b354b5e/Mystery_order_-_Thumbnail_-_US.jpg',
    //           srcMobile: '',
    //           alt: '',
    //           width: 256,
    //           height: 455,
    //         },
    //       },
    //     ],
    //   },
    // },
    // {
    //   id: '3301350034',
    //   type: 'ArticleCardsList',
    //   hiddenDevices: [],
    //   props: {
    //     title: 'The latest in refurbished tech',
    //     guides: [
    //       {
    //         id: '5a3dukZXCiY9Rp6yqGnz2V',
    //         tagline: 'content',
    //         category: 'content',
    //         translatedCategory: 'Back Market Articles and Guides',
    //         title: 'Refurbished vs new: Why refurb is better',
    //         text: 'When comparing new devices versus refurbished tech, refurbished takes the cake. Here’s why.',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/4WnBNuskQM6M6XSqUDzfM7/7f5c6e056d3eae7dedc64c2584b7483a/Thumbnail.png',
    //           alt: 'Refurbished vs new: Why refurb is better',
    //         },
    //         link: {
    //           name: 'article',
    //           type: 'internal',
    //           pattern: '/:locale/c/:category/:pageName',
    //           params: {
    //             category: 'content',
    //             pageName: 'new-vs-refurbished',
    //             locale: 'en-us',
    //           },
    //           href: 'https://preprod.backmarket.com/en-us/c/content/new-vs-refurbished',
    //         },
    //       },
    //       {
    //         id: '3zGYVV26U7IXhFJB55i7PF',
    //         tagline: 'news',
    //         category: 'news',
    //         translatedCategory: 'News',
    //         title:
    //           'What’s the difference between a locked and unlocked phone?',
    //         text: 'What’s the difference between a locked and unlocked phone? We break it down to help you find the right phone for you.',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/d0605b78c85d3b0b09a9dcc76d28fd93-meta-526-276/235d1caf12a040da70d5ea6a07b5bb5a/Locked_Banner_meta.png',
    //           alt: 'What’s the difference between a locked and unlocked phone?',
    //         },
    //         link: {
    //           name: 'article',
    //           type: 'internal',
    //           pattern: '/:locale/c/:category/:pageName',
    //           params: {
    //             category: 'news',
    //             pageName: 'locked-unlocked',
    //             locale: 'en-us',
    //           },
    //           href: 'https://preprod.backmarket.com/en-us/c/news/locked-unlocked',
    //         },
    //       },
    //       {
    //         id: '4XIEwc9Q0pXCu5EZRQscM7',
    //         tagline: 'news',
    //         category: 'news',
    //         translatedCategory: 'News',
    //         title: 'How Trade-in helps the planet — and your wallet',
    //         text: 'Our trade in program is an easy, contract-free way to make cash, help the planet, and clear out your old tech.',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/3s0UkhOhWmOuC4n34o1Utu/2de20599072d584d28d882ebdefad4fb/Banner_1.jpg',
    //           alt: 'How Trade-in helps the planet — and your wallet',
    //         },
    //         link: {
    //           name: 'article',
    //           type: 'internal',
    //           pattern: '/:locale/c/:category/:pageName',
    //           params: {
    //             category: 'news',
    //             pageName: 'trade-in-planet',
    //             locale: 'en-us',
    //           },
    //           href: 'https://preprod.backmarket.com/en-us/c/news/trade-in-planet',
    //         },
    //       },
    //       {
    //         id: '1k2AiuWMTV81DbexolawSd',
    //         tagline: 'news',
    //         category: 'news',
    //         translatedCategory: 'News',
    //         title: 'What does refurbished mean for the environment?',
    //         text: 'Buying refurbished tech and electronics can impact more than just your savings. We now have scientific proof that refurbished electronics help the planet.',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/6PzfDKxbFpl1A78wU9Rsdo/9b85ddcd98d51716a018e644a3f3b061/Banners_-_Blog.png',
    //           alt: 'What does refurbished mean for the environment?',
    //         },
    //         link: {
    //           name: 'article',
    //           type: 'internal',
    //           pattern: '/:locale/c/:category/:pageName',
    //           params: {
    //             category: 'news',
    //             pageName: 'impact-of-refurbished-on-environment',
    //             locale: 'en-us',
    //           },
    //           href: 'https://preprod.backmarket.com/en-us/c/news/impact-of-refurbished-on-environment',
    //         },
    //       },
    //     ],
    //   },
    // },
    // {
    //   id: '56ZdUO4TyBxOiEATqnsAUN',
    //   type: 'Categories',
    //   hiddenDevices: [],
    //   props: {
    //     title: 'Accessories, PCs, and more',
    //     subtitle: 'We’re more than just smartphones',
    //     categories: [
    //       {
    //         id: '4yhQMAFZ1jYZNtwqJu0Hk9',
    //         title: 'AirPods and Earphones',
    //         tagline: 'Sustainably sourced audio',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/2bd7868dfa2135c46d0f6124c01c8d2d/image__25_-removebg-preview__1_.png',
    //           srcMobile: '',
    //           alt: 'AirPods & Earphones',
    //           width: 400,
    //           height: 400,
    //         },
    //         link: null,
    //       },
    //       {
    //         id: '4PkvMzvYA0PI645z8uRRSd',
    //         title: 'Apple Watch',
    //         tagline: 'Talk to your watch',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/3YMiDmVjmnXTkri7J7qWW0/5ef3f6a5eba7fbef14346e853c943f85/Apple_Watch_Series_8_-_Black.png',
    //           srcMobile:
    //             'https://images.ctfassets.net/mmeshd7gafk1/7dxmoX1a6jzPgz7F2xtWzW/c048fe7b6cd3ec557366a07c02bc2883/Apple_Watch_Series_8_-_Black.png',
    //           alt: 'Apple Watch Series 8 - Black',
    //           width: 400,
    //           height: 400,
    //           widthMobile: 200,
    //           heightMobile: 200,
    //         },
    //         link: null,
    //       },
    //       {
    //         id: '7LjfYE4s4bwkaNe534vO5M',
    //         title: 'Samsung Galaxy',
    //         tagline: 'Explore the vast universe beyond Apple',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/8WLU4Bl5AhEY3740tw0sE/b940bce9d1085423548fc737c9e5d870/Samsung_Galaxy.jpg',
    //           srcMobile: '',
    //           alt: 'Samsung Galaxy',
    //           width: 400,
    //           height: 400,
    //         },
    //         link: null,
    //       },
    //       {
    //         id: '1HBynlWfnRljpmYqpbXEab',
    //         title: 'iMac',
    //         tagline: 'Welcome to the big screen',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/7FAUu1tdzf31WyvhwyGswV/8204f02eca530f52be7057435ad3f8a8/iMac_2021_-_Blue.png',
    //           srcMobile: '',
    //           alt: 'iMac 2021 - Blue',
    //           width: 400,
    //           height: 400,
    //         },
    //         link: null,
    //       },
    //       {
    //         id: '4s7XhSE6hyE93N2xeQabNM',
    //         title: 'Bestsellers',
    //         tagline: 'Better than ever, and up to 70% off',
    //         image: {
    //           src: 'https://images.ctfassets.net/mmeshd7gafk1/7AyYcgI1C2D1CF3Q24vZSa/cba86f5f92d222595c0eef0c4084e296/Capture.jpg',
    //           srcMobile: '',
    //           alt: 'Good Deals - Bestsellers - Cat Bloc',
    //           width: 400,
    //           height: 400,
    //         },
    //         link: {
    //           type: 'internal',
    //           name: 'event-page',
    //           params: {
    //             pageName: 'best-sellers',
    //           },
    //           query: {},
    //           hash: {},
    //           href: 'https://preprod.backmarket.com/en-us/e/best-sellers',
    //         },
    //       },
    //     ],
    //   },
    // },
    {
      id: 'fSPc7dv6dwGjSa7DAkhRs',
      type: 'ContentMedia',
      hiddenDevices: [],
      props: {
        contentTitle:
          'Trading in your old tech is easier than a lot of things.',
        text: 'Earn cash when you trade in your forgotten tech. It’s a simple way to help do more with what we already have.',
        textPosition: 'right',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.com/en-us/buyback/home',
          },
          label: 'Get started',
        },
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/4GY8ZXxlzpdhjzhNYgOoHq/953fa0dacf6f03fa2b5f127987fa8b7d/BUYBACK_CountryBusinessLaunch_EventPage_Bloc_3__1_.png',
          srcMobile: '',
          alt: 'Homepage buyback',
          width: 960,
          height: 824,
        },
      },
    },
    {
      id: '3ypwepB9aSQoCVulmHfh9e',
      type: 'ContentMedia',
      hiddenDevices: ['desktop'],
      props: {
        contentTitle: 'We’re B-Corp certified',
        text: 'Being a B-Corp means that we walk the walk when it comes to everything from our environmental impact to our hiring practices. ',
        textPosition: 'right',
        ctaLink: {
          link: {
            href: 'https://www.bcorporation.net/en-us/resources/',
          },
          label: 'Read more',
        },
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/2oGLCC47CTvCGCGHS6NZ4z/bd7ec4e48532de086f2754692bfd2b25/bcorp_content.webp',
          srcMobile:
            'https://images.ctfassets.net/mmeshd7gafk1/3o6LHjQw0gusTsB3OZfDiE/72494c4817171267d0f98c567e9c5fa9/frame_3826.webp',
          alt: 'B Corp logo with a green background',
          width: 960,
          height: 824,
          widthMobile: 327,
          heightMobile: 412,
        },
      },
    },
    {
      id: '7CxBUUOLH67MTrVIaFuzMt',
      type: 'ContentMedia',
      hiddenDevices: ['mobile'],
      props: {
        contentTitle: 'We’re B-Corp certified',
        text: 'Being a B-Corp means that we walk the walk when it comes to everything from our environmental impact to our hiring practices. ',
        textPosition: 'left',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.com/en-us/c/news/bcorp-status',
          },
          label: 'Read more',
        },
        image: {
          src: 'https://images.ctfassets.net/mmeshd7gafk1/2oGLCC47CTvCGCGHS6NZ4z/bd7ec4e48532de086f2754692bfd2b25/bcorp_content.webp',
          srcMobile:
            'https://images.ctfassets.net/mmeshd7gafk1/3o6LHjQw0gusTsB3OZfDiE/72494c4817171267d0f98c567e9c5fa9/frame_3826.webp',
          alt: 'B Corp logo with a green background',
          width: 960,
          height: 824,
          widthMobile: 327,
          heightMobile: 412,
        },
      },
    },
    {
      id: '4McYP0tpMRhhbNlNSWItuR',
      type: 'EmailCollector',
      hiddenDevices: [],
      props: {
        title:
          'Sign up for our emails and get $15 off your first purchase of $250 or more.',
        text: 'Yep, it’s that easy.',
        buttonLabel: 'Sign up',
      },
    },
    {
      id: '6UOT5dDvOYXD06EQMTm8Kp',
      type: 'PressCarousel',
      hiddenDevices: [],
      props: {
        title: ' In the news',
        articles: [
          {
            id: '1YoUup0ZqpouHYyAbWBoOi',
            text: '‘It should be a right to fix your phone’: the boss of booming secondhand tech firm Back Market',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5lkQ7AtIqBkDVPqj9iunL1/ee98c2254b423fe8adf9043bf3d6a77e/the_guardient_2.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/598Jad9FJKIGzdZq0oPgZE/c58b1fe5f0ce73ba54f21430e60c724b/the_guardient_2.png',
              alt: 'The guardian logo',
              width: 2560,
              height: 842,
              widthMobile: 2560,
              heightMobile: 842,
            },
            link: {
              href: 'https://www.theguardian.com/business/article/2024/jun/11/it-should-be-a-right-to-fix-your-phone-the-boss-of-booming-secondhand-tech-firm-back-market',
            },
            publicationDate: '06/2024',
          },
          {
            id: '1dqV0A44OQYItgDoAljpr5',
            text: 'Back Market and bolt Announce Partnership in the United States',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5no9s5GynkjmZpMpNM4yLe/674a31b2d1bb154eec69a21335140492/accesswire_big.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7lExpEAlGLTZN3hB3f7kqO/fa615600539c74dd69c647796f4e4956/accesswire_big.png',
              alt: 'Accesswire Logo',
              width: 300,
              height: 100,
              widthMobile: 300,
              heightMobile: 100,
            },
            link: {
              href: 'https://www.accesswire.com/860326/back-market-and-bolt-announce-partnership-in-the-united-states',
            },
            publicationDate: '05/2024',
          },
          {
            id: '10jbkiqTvCFmEGBeUiCV0D',
            text: "Back Market Takes a Bite Out of Apple's Keynote Event With Ad Hyping Refurbished Devices",
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/1f6nZ6MvaNwrlzeBcmfJ5E/b8666491bba7d8daa28ae45eff6ddd2b/Adweek.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1f6nZ6MvaNwrlzeBcmfJ5E/b8666491bba7d8daa28ae45eff6ddd2b/Adweek.png',
              alt: 'Adweek',
              width: 500,
              height: 500,
              widthMobile: 500,
              heightMobile: 500,
            },
            link: {
              href: 'https://www.adweek.com/brand-marketing/back-market-apple-keynote-event-ad-hyping-refurbished-devices/',
            },
            publicationDate: '10/2023',
          },
          {
            id: '1u5pDSBILXAARliKyFpsUl',
            text: 'How a Traded-In Apple iPhone Gets Refurbished',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3DbPmmTF29p8XRMDkdpcKO/c8ebbac388b93529bcd754d46c0b41ff/WSJ_logo.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4x3pmI5avrmy52JiZeEd05/38dc34d224672b17a9b326fada02dba4/WSJ_logo.png',
              alt: 'WSJ logo',
              width: 3840,
              height: 2160,
              widthMobile: 3840,
              heightMobile: 2160,
            },
            link: {
              href: 'https://youtu.be/WHqxBlFuIXA',
            },
            publicationDate: '03/2023',
          },
          {
            id: '7dnJaeUuCX77nmsat4Fc2a',
            text: 'How Back Market Paves The Way For Sustainable Consumption',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4HGEQI2k9nVoZ8qWTBfd3B/471f609451fbff7b5f20eed819ff48e2/forbes.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4HGEQI2k9nVoZ8qWTBfd3B/471f609451fbff7b5f20eed819ff48e2/forbes.jpg',
              alt: 'Forbes Logo',
              width: 650,
              height: 455,
              widthMobile: 650,
              heightMobile: 455,
            },
            link: {
              href: 'https://www.forbes.com/sites/christophermarquis/2023/04/17/how-back-market-paves-the-way-for-sustainable-consumption/amp/',
            },
            publicationDate: '04/2023',
          },
          {
            id: '2ORxzImkbO46EUmCQxQMyr',
            text: 'How to Get Rid of Old Electronics',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6N0pM6qXsBGWauwKFGEjz2/e7b438ad6353bc63053fbbdcce4906c6/Wirecutter.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6N0pM6qXsBGWauwKFGEjz2/e7b438ad6353bc63053fbbdcce4906c6/Wirecutter.png',
              alt: 'The New York Times Wirecutter',
              width: 471,
              height: 107,
              widthMobile: 471,
              heightMobile: 107,
            },
            link: {
              href: 'https://www.nytimes.com/wirecutter/blog/how-to-get-rid-of-old-electronics/',
            },
            publicationDate: '05/2023',
          },
          {
            id: 'dvSfjKsahK44shLVufAOG',
            text: 'There’s nothing wrong with buying refurbished wireless headphones – here’s why',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4wIR768rEflMSASvrsdZJK/b0db929e83ad94bdabb6005df05210b2/260c6ce84df3eef68845e878eee6e2f6.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/1GIxbB8d1zVcTlqjvqPMnE/d84ec9ee3ee5b4cf802e9619f1c211d3/260c6ce84df3eef68845e878eee6e2f6.png',
              alt: 'Laptop',
              width: 386,
              height: 98,
              widthMobile: 386,
              heightMobile: 98,
            },
            link: {
              href: 'https://www.laptopmag.com/features/nothing-wrong-buying-refurbished-headphones',
            },
            publicationDate: '05/2023',
          },
          {
            id: '6hiPsQZtcWNfmkGwSBrciZ',
            text: 'Back Market reaches $5.7B valuation for its refurbished device marketplace',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5xba1oYxIY2L1zp0qrRbCg/df7e74866da5305f842fc12336ac8ddc/TC_logo.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5xba1oYxIY2L1zp0qrRbCg/df7e74866da5305f842fc12336ac8ddc/TC_logo.png',
              alt: 'TechCrunch',
              width: 1889,
              height: 285,
              widthMobile: 1889,
              heightMobile: 285,
            },
            link: {
              href: 'https://techcrunch.com/2022/01/11/back-market-reaches-5-7b-valuation-for-its-refurbished-device-marketplace/',
            },
            publicationDate: '01/2022',
          },
          {
            id: '69M42ElENnBRCuEDMKO6WL',
            text: 'Back Market launches electronics BuyBack program paying customers top dollar for their old devices',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4E1NNaQVotJDFTESRqBK9S/0720c450945f8162f62ba21e13c14fc1/digital-journal_logo.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4E1NNaQVotJDFTESRqBK9S/0720c450945f8162f62ba21e13c14fc1/digital-journal_logo.jpg',
              alt: 'Digital Journal',
              width: 600,
              height: 100,
              widthMobile: 600,
              heightMobile: 100,
            },
            link: {
              href: 'https://www.digitaljournal.com/pr/back-market-launches-electronics-buyback-program-paying-customers-top-dollar-for-their-old-devices',
            },
            publicationDate: '03/2022',
          },
          {
            id: '5WjWKGv0oZa6kzfIT2U2IM',
            text: 'Back Market becomes France’s most valuable startup',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2xfafRQtTGQFMe8u9eJae4/0b5100a396b8f0e341c9ced35ede6445/sifted_logo.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2xfafRQtTGQFMe8u9eJae4/0b5100a396b8f0e341c9ced35ede6445/sifted_logo.jpg',
              alt: 'Sifted',
              width: 600,
              height: 100,
              widthMobile: 600,
              heightMobile: 100,
            },
            link: {
              href: 'https://sifted.eu/articles/back-market-france-most-valuable-startup/',
            },
            publicationDate: '01/2022',
          },
          {
            id: '5JuY7Gbbeg4YrvDdpLo0o3',
            text: 'Why You Might Want to Buy a Refurbished Phone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2iflmtFNdtLLIhPmEf3TuA/2536b384c397a352f1af2c3c87b8b595/lifewire_logo.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2iflmtFNdtLLIhPmEf3TuA/2536b384c397a352f1af2c3c87b8b595/lifewire_logo.jpg',
              alt: 'Lifewire',
              width: 600,
              height: 100,
              widthMobile: 600,
              heightMobile: 100,
            },
            link: {
              href: 'https://www.lifewire.com/why-you-might-want-to-buy-a-refurbished-phone-5202717',
            },
            publicationDate: '09/2021',
          },
          {
            id: 'ISOdpbHCzd1PqESBEDUNg',
            text: 'This refurbished electronics site takes the risk out of buying used iPhones, MacBooks, PlayStations and more',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2IZiuzYAegcLJaQkvpG0fW/1f61b12a52c99b02fe193ae7db46cfd4/fastcompany_logo.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2IZiuzYAegcLJaQkvpG0fW/1f61b12a52c99b02fe193ae7db46cfd4/fastcompany_logo.jpg',
              alt: 'Fast Company',
              width: 600,
              height: 100,
              widthMobile: 600,
              heightMobile: 100,
            },
            link: {
              href: 'https://www.fastcompany.com/90505532/this-refurbished-electronics-site-takes-the-risk-out-of-buying-used-iphones-macbooks-playstations-and-more',
            },
            publicationDate: '05/2020',
          },
          {
            id: '4z3JdCdXdrlXvcpsjK3Nyh',
            text: 'Refurbished smartphones, tablets, iPhones and iPads: a great deal',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6ZHbeN5dBSBHew9QXsHTjd/43e890161840234016cb3e01513e5a1a/techgameworld-logo2.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6ZHbeN5dBSBHew9QXsHTjd/43e890161840234016cb3e01513e5a1a/techgameworld-logo2.png',
              alt: 'Tech Game World',
              width: 261,
              height: 58,
              widthMobile: 261,
              heightMobile: 58,
            },
            link: {
              href: 'https://techgameworld.com/refurbished-smartphones-tablets-iphones-and-ipads-a-great-deal/',
            },
            publicationDate: '01/2023',
          },
          {
            id: '6g1k2CpUxQ95sa0ueExDc5',
            text: 'Refurbished tech 101: How to get the best deals',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6kpSJv8x4fB5lSfAli4pEi/3335854b3f7e3ab62da8c12428370b7d/GMA__Good_Morning_America__logo.jpg',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6kpSJv8x4fB5lSfAli4pEi/3335854b3f7e3ab62da8c12428370b7d/GMA__Good_Morning_America__logo.jpg',
              alt: 'Good Morning America',
              width: 486,
              height: 393,
              widthMobile: 486,
              heightMobile: 393,
            },
            link: {
              href: 'https://www.goodmorningamerica.com/living/video/refurbished-tech-101-best-deals-87865518',
            },
            publicationDate: '08/2022',
          },
          {
            id: '63prFQIoicndt0S2qHL0TI',
            text: 'Will We Ever Achieve a Circular Economy?',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4dL8aFuH9CWU2SHMu8Wxo9/20accbbd41ab258f36288a9877c4a7d0/built-in-logo-vector.png',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/4dL8aFuH9CWU2SHMu8Wxo9/20accbbd41ab258f36288a9877c4a7d0/built-in-logo-vector.png',
              alt: 'Built in',
              width: 900,
              height: 500,
              widthMobile: 900,
              heightMobile: 500,
            },
            link: {
              href: 'https://builtin.com/greentech/circular-economy',
            },
            publicationDate: '08/2022',
          },
          {
            id: '1ez3fbIbcU7SqN83YiDDFS',
            text: 'Back Market launches electronics BuyBack program paying customers top dollar for their old devices',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/46XvXLAEOWhUo6HRc5V63l/af06d92ba694d388cb1b80317ee99f46/Digital-Journal-logo.webp',
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/46XvXLAEOWhUo6HRc5V63l/af06d92ba694d388cb1b80317ee99f46/Digital-Journal-logo.webp',
              alt: 'Digital Journal',
              width: 500,
              height: 500,
              widthMobile: 500,
              heightMobile: 500,
            },
            link: {
              href: 'https://www.digitaljournal.com/pr/back-market-launches-electronics-buyback-program-paying-customers-top-dollar-for-their-old-devices',
            },
            publicationDate: '03/2022',
          },
        ],
      },
    },
    {
      id: '1my3yOCox5g4yDNGBXPC5i',
      type: 'Faq',
      hiddenDevices: [],
      props: {
        title: 'What else?',
        subtitle: '4 questions people always ask',
        questions: [
          {
            id: '1oReajvnB3Gl4e337uxUJx',
            title: 'How does Back Market guarantee quality?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'From a pre-listing screening process for all sellers to “mystery orders” placed by Back Market’s Innovation Lab, ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.com/en-us/quality',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value:
                            'we give 110% in making sure the Verified Refurbished tech we offer you is top-notch',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        '. It’s also why every device comes with a 1-year warranty and 30 days to change your mind.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '4Rt1h3eu5VgBTOO56SXXZj',
            title: 'What’s the difference between refurbished and new? ',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "On the outside, a refurbished smartphone looks and works like new. But it’s what's on the inside that really counts. Reborn tech ",
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.com/en-us/c/news/impact-of-refurbished-on-environment',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value:
                            'helps keep e-waste out of our landfills, water, and air',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value: '.\n\n',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6RTiOHirtwcCkLKujnWIyW',
            title: 'Can you help me recycle my old phone or other electronics?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value: 'We can do you one better — give you cash with ',
                      marks: [],
                      data: {},
                    },
                    {
                      nodeType: INLINES.HYPERLINK,
                      data: {
                        uri: 'https://www.backmarket.com/en-us/buyback/home',
                      },
                      content: [
                        {
                          nodeType: 'text',
                          value: 'Trade-In',
                          marks: [],
                          data: {},
                        },
                      ],
                    },
                    {
                      nodeType: 'text',
                      value:
                        '. You can get an offer in minutes from a refurbisher to trade in your old device. To make it extra juicy, we also cover the cost of shipping. \n',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '6AE0PHjjLmVhjKkIL1Nytb',
            title:
              'Was the T-800 in Terminator 2: Judgment Day (1991) technically “refurbished”?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Most likely. We’d need to hand Skynet over to our Innovation Lab before we say yes or no, but we have a hunch that it’s an “I’ll be back” scenario.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.com/en-us/help',
          },
          label: 'More FAQs',
        },
      },
    },
  ],
  hideFromSearchEngine: false,
}

export default HOME_FALLBACK_DATA
